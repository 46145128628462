<template>
  <div>
    <el-card class="intanle">
      <span class="h1">{{ title }}</span>
      <span @click="goBack()">
        <el-button class="el-button1" type="info" style="float: right">戻る</el-button>
      </span>
    </el-card>
    <el-card>
      <!-- 情報入力 -->
      <el-form ref="form" label-position="right" label-width="20%" :rules="rules" :model="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商店番号:" prop="merchantNo">
              <el-input placeholder="商店番号を入力してください（例: AS" v-model="form.merchantNo" :disabled="disabledBH" style="width: 45%" maxlength="2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商店名:" prop="merchantName">
              <el-input placeholder="商店名を入力してください" v-model="form.merchantName" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商店銀行名:" prop="companyBankName">
              <el-input placeholder="商店銀行名を入力してください" v-model="form.companyBankName" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商店銀行口座番号:" prop="companyBankNo">
              <el-input placeholder="会社銀行口座番号を入力してください" v-model="form.companyBankNo" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商店会社全名:" prop="companyName">
              <el-input placeholder="商店会社全名を入力してください" v-model="form.companyName" :disabled="disabled" style="width: 45%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="this.type != 'insert'">
            <el-form-item label="利用可能か:" prop="enabled">
              <el-radio-group id="enabled" v-model="form.enabled" :disabled="disabled">
                <el-radio :label="'1'">利用可能</el-radio>
                <el-radio :label="'0'">利用不可</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="logtime" v-if="this.type != 'insert'">
          <el-col :span="12">
            <el-form-item label="作成日時:" label-width="20%">
              {{ createTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新日時:" label-width="20%">
              {{ updateTime }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作成者:" label-width="20%">
              {{ createPersonName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="更新者:" label-width="20%">
              {{ updatePersonName }}
            </el-form-item>
          </el-col>
        </div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="" v-if="this.type != 'details'">
              <el-button type="primary" @click="onSubmit('form')">{{ content }}</el-button>
              <el-button @click="goBack">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import merchantService from '@/api/merchant/merchantService'
export default {
  inject: ['reload'],
  name: 'GetStaffDetails',
  components: {},
  data() {
    return {
      merchantService: new merchantService(), //インターフェース定義
      type: this.$route.query.type, // 遷移タイプ
      merchantId: this.$route.query.merchantId, //商店ID
      loading: false, //ローディング
      form: {
        merchantNo: '', //商店番号
        merchantName: '', //商店名
        companyName: '', //会社名
        companyBankName: '', //会社銀行名
        companyBankNo: '', //会社銀行番号
        enabled: '1' //利用可能かどうか
      },
      title: '', // 詳細タイトル
      content: '', //ボタンのテキスト
      disabled: false, //編集可能かどうか
      disabledBH: false, //編集可能かどうか
      merchant: '関連商店を選択してください',
      createTime: '', //作成日時
      updateTime: '', //更新日時
      createPersonName: '', //
      updatePersonName: '', //
      headerName: '', //パンくずリストのタイトル
      fromPath: '',
      // 非空のバリデーション
      rules: {
        merchantNo: [
          {
            required: true,
            pattern: new RegExp(/^[A-Z]{2}$/),
            message: '商店番号を入力してください。形式は2文字の大文字英字です。',
            trigger: 'blur'
          }
        ],
        merchantName: [
          {
            required: true,
            message: '商店名を入力してください',
            trigger: 'blur'
          }
        ],
        companyName: [
          {
            required: true,
            message: '会社名を入力してください',
            trigger: 'change'
          }
        ],
        companyBankName: [
          {
            required: true,
            message: '会社銀行名を入力してください',
            trigger: 'change'
          }
        ],
        companyBankNo: [
          {
            required: true,
            message: '会社銀行番号を入力してください',
            trigger: 'change'
          }
        ]
      }
    }
  },

  created() {
    if (this.type == 'details') {
      this.title = '商店情報詳細'
      this.headerName = '商店情報詳細'
      this.disabled = true
      this.disabledBH = true
      this.GetMerchantsDetails()
    } else if (this.type == 'update') {
      this.title = '商店情報修正'
      this.headerName = '商店情報修正'
      this.content = '保存'
      this.disabledBH = true
      this.GetMerchantsDetails()
    } else if (this.type == 'insert') {
      this.title = '商店情報新規追加'
      this.headerName = '商店情報新規追加'
      this.content = '追加'
    }
  },

  methods: {
    //商品カテゴリの取得
    async GetCategoryList() {
      this.loading = false
      const { data: res } = await this.$http.get(this.$url.GetCategoryList, {
        params: {
          // categoryName: encodeURIComponent(this.categoryName),
          currentPage: 1,
          pageSize: 50
        }
      })
      if (res.msgCode == 1) {
        var categorylistD = res.dtList
        categorylistD.forEach(item => {
          this.categorylist.push({
            label: item.categoryName,
            value: item.categoryId
          })
        })
      } else {
        this.$message.error(res.message)
      }
    },
    //商店情報詳細の取得
    async GetMerchantsDetails() {
      const date = {
        accessToken: localStorage.getItem('authToken'),
        currentRole: localStorage.getItem('currentRole'),
        merchantId: this.merchantId //商店ID
      }
      this.merchantService.detail(date).then(res => {
        if (res.data.success == 'true') {
          this.form = res.data.result
          this.createTime = res.data.result.createTime
          this.updateTime = res.data.result.updateTime
          this.createPersonName = res.data.result.updatePersonName
          this.updatePersonName = res.data.result.updatePersonName
        } else {
          this.$message.error(res.data.message)
        }
      })
    },

    //商店情報新規追加
    onSubmit(formName) {
      if (this.type == 'insert') {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            const date = {
              accessToken: localStorage.getItem('authToken'),
              currentRole: localStorage.getItem('currentRole'),
              merchantNo: this.form.merchantNo, //商店番号
              merchantName: this.form.merchantName, //商店名
              companyName: this.form.companyName, //会社名
              companyBankName: this.form.companyBankName, //会社銀行名
              companyBankNo: this.form.companyBankNo //会社銀行番号
            }
            this.merchantService.add(date).then(res => {
              if (res.data.success == 'true') {
                this.$message.success('保存成功')
                this.goBack()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      } else if (this.type == 'update') {
        this.UpdateStaff(formName)
      }
    },
    //商店情報修正
    async UpdateStaff(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const date = {
            accessToken: localStorage.getItem('authToken'),
            currentRole: localStorage.getItem('currentRole'),
            merchantId: this.merchantId, //商店ID
            merchantNo: this.form.merchantNo, //商店番号
            merchantName: this.form.merchantName, //商店名
            companyName: this.form.companyName, //会社名
            companyBankName: this.form.companyBankName, //会社銀行名
            companyBankNo: this.form.companyBankNo, //会社銀行番号
            enabled: this.form.enabled
          }
          this.merchantService.update(date).then(res => {
            if (res.data.success == 'true') {
              this.$message.success('保存成功')
              this.goBack()
            } else {
              this.$message.error(res.data.message)
            }
          })
        } else {
          return false
        }
      })
    },
    //前のページに戻る
    goBack() {
      if (this.fromPath == '/GetMerchantsList') {
        localStorage.setItem('updateState', true)
      }

      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.el-button1 {
  margin-top: 2px;
}

.el-card {
  margin-top: 15px;
}

textarea {
  height: 100px;
}
</style>
